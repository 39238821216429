import { db, auth, googleAuthProvider, facebookAuthProvider } from 'auth/FirebaseAuth';

const FirebaseService = {}

FirebaseService.signInEmailRequest = async (email, password) =>
	await auth.signInWithEmailAndPassword(email, password).then(user => user).catch(err => err);

FirebaseService.signInEmailRequest = async (email, password) =>
  await auth.signInWithEmailAndPassword(email, password).then(user => user).catch(err => err);

FirebaseService.forgetPassword = async (email) =>
  await auth.sendPasswordResetEmail(email).then(user => user).catch(err => err);
		
FirebaseService.signOutRequest = async () =>
	await auth.signOut().then(user => user).catch(err => err);

FirebaseService.signInGoogleRequest = async () =>
  await auth.signInWithPopup(googleAuthProvider).then(user => user).catch(err => err);

FirebaseService.signInFacebookRequest = async () =>
  await auth.signInWithPopup(facebookAuthProvider).then(user => user).catch(err => err);

FirebaseService.signUpEmailRequest = async (email, password) =>
	await auth.createUserWithEmailAndPassword(email, password).then(user => user).catch(err => err);	

// Llamadas a DB
FirebaseService.getPrimaryUserData = async (uid) =>	
  await db.ref(`Clients/${uid}`).once('value', () => {
  }).then(snap => snap).catch(err => err);
	
export default FirebaseService
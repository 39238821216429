import React, { lazy, Suspense } from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import firebase from 'firebase/app';
import { 
	signInSecondary
} from 'redux/actions/Auth';

export const AppViews = props => {

  const { 
		signInSecondary
	} = props

  // MPENA
  // En esta parte consultamos al listado de apps de Firebase inicializadas.
  // Si hay menos de dos instancias, es que hemos perdido conexión con la DB secundaria
  const apps = firebase.apps
  if(apps.length < 2){
    // Obtener data para conectar con DB secundaria tras refresh
    const primaryDataRaw = localStorage.getItem("PRIMARY_DATA")
    const primaryData = JSON.parse(primaryDataRaw)
    // Manda a loguear con la DB secundaria, con los datos de la DB primaria
    signInSecondary(primaryData);  
  }
  
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./home`))} />
        <Route path={`${APP_PREFIX_PATH}/charts`} component={lazy(() => import(`./charts`))} />
        <Route path={`${APP_PREFIX_PATH}/universal/users`} component={lazy(() => import(`./universal/users`))} />
        <Route path={`${APP_PREFIX_PATH}/universal/sessions`} component={lazy(() => import(`./universal/sessions`))} />
        <Route path={`${APP_PREFIX_PATH}/apps/learning/Logistics/Riesgos`} component={lazy(() => import(`./apps/learning/Logistics/Riesgos`))} />
        <Route path={`${APP_PREFIX_PATH}/apps/learning/Logistics/FlujoTrabajo`} component={lazy(() => import(`./apps/learning/Logistics/FlujoTrabajo`))} />
        <Route path={`${APP_PREFIX_PATH}/apps/learning/Logistics/Vestimenta`} component={lazy(() => import(`./apps/learning/Logistics/Vestimenta`))} />
        <Route path={`${APP_PREFIX_PATH}/learning/boards`} component={lazy(() => import(`./learning/boards`))} />
        <Route path={`${APP_PREFIX_PATH}/learning/boardsDetail`} component={lazy(() => import(`./learning/boardsDetail`))} />
        <Route path={`${APP_PREFIX_PATH}/learning/sequenceSelector`} component={lazy(() => import(`./learning/sequenceSelector`))} />
        <Route path={`${APP_PREFIX_PATH}/apps/learning/Logistics/PartesPiezas`} component={lazy(() => import(`./apps/learning/Logistics/PartesPiezas`))} />
        <Route path={`${APP_PREFIX_PATH}/apps/learning/Logistics/ConstruccionLogistica`} component={lazy(() => import(`./apps/learning/Logistics/ConstruccionLogistica`))} />
        <Route path={`${APP_PREFIX_PATH}/apps/learning/Edification/Vestimenta`} component={lazy(() => import(`./apps/learning/Edification/Vestimenta`))} />
        <Route path={`${APP_PREFIX_PATH}/apps/learning/Gastronomia/FlujoTrabajo`} component={lazy(() => import(`./apps/learning/Gastronomia/FlujoTrabajo`))} />
        <Route path={`${APP_PREFIX_PATH}/apps/learning/SNA/Riego/PartesPiezas`} component={lazy(() => import(`./apps/learning/SNA/Riego/PartesPiezas`))} />
        <Route path={`${APP_PREFIX_PATH}/apps/learning/SNA/Riego/Montaje`} component={lazy(() => import(`./apps/learning/SNA/Riego/Montaje`))} />
        <Route path={`${APP_PREFIX_PATH}/apps/learning/SNA/Riego/FlujoTrabajo`} component={lazy(() => import(`./apps/learning/SNA/Riego/FlujoTrabajo`))} />
        <Route path={`${APP_PREFIX_PATH}/apps/learning/SNA/Alimentacion/Bodega`} component={lazy(() => import(`./apps/learning/SNA/Alimentacion/Bodega`))} />
        <Route path={`${APP_PREFIX_PATH}/apps/learning/SNA/Alimentacion/Cocina`} component={lazy(() => import(`./apps/learning/SNA/Alimentacion/Cocina`))} />
        <Route path={`${APP_PREFIX_PATH}/apps/learning/SNA/Alimentacion/Comedor`} component={lazy(() => import(`./apps/learning/SNA/Alimentacion/Comedor`))} />
        <Route path={`${APP_PREFIX_PATH}/apps/learning/test`} component={lazy(() => import(`./apps/learning/Test`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  )
}

const mapStateToProps = ({auth}) => {
	const {loading, message, showMessage, token, redirect} = auth;
  return {loading, message, showMessage, token, redirect}
}

const mapDispatchToProps = {
	signInSecondary
}

// export default React.memo(AppViews);

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(AppViews))
export default function versionHandler() {
    fetch('../resources/version.json', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then(function f(response) {
        console.log(response)
        return response.json()
      })
      .then(function g(myJson) {
        console.log(myJson)
        global.version = myJson
        window.localStorage.setItem("version", JSON.stringify(myJson));
      })
  }
  